.select-wrapper {

  .select-box:focus-within::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .select-box {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;
    font-family: "Cera Pro";
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;

    &__current {
      position: relative;
      cursor: pointer;
      outline: none;

      &:focus {
        position: relative;

        & + .select-box__list {
          opacity: 1;
          animation-name: none;

          .select-box__option {
            cursor: pointer;
          }
        }

        .select-box__icon {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &__icon {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      transform-origin: center;
      color: #E64210;
      transition: 0.2s ease;
    }

    &__value {
      display: flex;

      .select-box__input-label {
        display: none;
      }
    }

    &__input {
      display: none;

      &:checked + .select-box__input-text {
        display: block;

        & + .select-box__input-label {
          display: block;
        }
      }
    }

    &__input-text {
      display: none;
      width: 100%;
      margin: 0;
      padding: 16px 24px 20px 24px;
      background-color: transparent;
      border: 1px solid #A7B719;
    }

    &__list {
      position: absolute;
      width: 100%;
      padding: 0;
      list-style: none;
      opacity: 0;
      z-index: 100;


      animation-name: HideList;
      animation-duration: 0.5s;
      animation-delay: 0.5s;
      animation-fill-mode: forwards;
      animation-timing-function: step-start;
      box-shadow: 0 15px 30px -10px transparentize(#000, 0.9);
    }

    &__option {
      display: block;
      padding: 16px 24px 20px 24px;
      background-color: #013338;
      border: 1px solid #A7B719;
      border-top: none;

      &:hover,
      &:focus {
        color: #FFFFFF;
        background-color: #4C9099;
      }
    }
  }

  @keyframes HideList {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }

}
