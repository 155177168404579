@import "../mixins/media";

.benefits {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media-min-width($s) {
    flex-direction: row;
    align-items: unset;
  }

  .custom-list {
    &--first-list {
      @include media-min-width($s) {
        margin-right: 50px;
      }

      @include media-min-width($l) {
        margin-right: 92px;
      }

      .second-list {
        @include media-min-width($s) {
          display: none !important;
        }
      }
    }

    &--second-list {
      display: none !important;

      @include media-min-width($s) {
        display: unset !important;
      }
    }
  }
}
