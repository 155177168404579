input,
textarea {
  background-color: transparent;
  border: 1px solid $color-light-green;
  padding: 16px 24px 20px 24px;
  color: $color-white;
  width: 100%;

  &,
  &::placeholder {
    color: $color-white;
  }

  &[type="file"] {
    background-color: rgba(#A7B719, .56);
  }
}

textarea {
  resize: none;
}

label {
  &.checkbox {
    display: flex;
    font-size: 11px;
    line-height: 14px;
    font-weight: $font-weight-regular;
    color: $color-white;

    .checkbox__inner {
      width: 16px;
      height: 16px;
      position: relative;

      input[type="checkbox"] {
        opacity: 0;
        width: unset;
        margin-right: 3px;
        margin-top: 2px;

        &:checked + .checkmark {
          border-color: $color-orange;
          background-color: $color-orange;

          &::before {
            display: block;
          }
        }
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        pointer-events: none;
        width: 100%;
        height: 100%;
        border: 1px solid $color-light-gray;
        border-radius: 5px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
          content: '\f00c';
          font-family: $font-family-FontAwesomeFree;
          font-weight: $font-weight-black;
          color: $color-dark-blue;
          font-size: 8px;
          display: none;
        }
      }
    }

    .text {
      margin-left: 9px;
      margin-top: -2px;
      max-width: calc(100% - 17px);
      font-size: 16px;
      line-height: 23px;
      font-weight: $font-weight-light;
    }
  }
}
