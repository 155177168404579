@import "../../mixins/media";

.about-us-section {
  position: relative;

  .glitch {
    position: absolute;

    &--top {
      display: none;

      @include media-min-width($xl) {
        display: block;
        right: 0;
        top: -243px;
      }
    }

    &--bottom {
      left: -1038px;
      top: 357px;
    }
  }

  &__container {
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;

    @include media-min-width($s) {
      margin-top: 99px;
      margin-bottom: 193px;
    }

    &__top {
      flex-direction: column;

      @include media-min-width($l) {
        flex-direction: row;
      }
    }

    &__bottom {
      flex-direction: column;

      @include media-min-width($m) {
        flex-direction: row;
      }

      &__left {
        flex-direction: column;

        @include media-min-width($xs) {
          flex-direction: row;
        }

        img {
          width: 100%;

          @include media-min-width($xs) {
            width: 50%;
          }

          @include media-min-width($m) {
            max-height: unset;
          }
        }
      }
    }

    .lines-img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -80px;
      z-index: -1;
      display: none;

      @include media-min-width($xl) {
        display: block;
      }
    }

    .text-box {
      background: linear-gradient(#4c9099 0%, #206990 100%);
      padding: 38px 24px;
      flex-shrink: 100;

      h5 {
        font-size: 22px;
        font-weight: $font-weight-black;
        text-transform: uppercase;

        @include media-min-width($xs) {
          font-size: 25px;
        }

        @include media-min-width($m) {
          font-size: 32px;
        }
      }

      p {
        line-height: 27px;
        font-weight: $font-weight-light;
        font-family: $font-family-DIN2014;
        font-size: 14px;
        margin-top: 12px;
        display: inline;

        a {
          font-weight: bold;
        }

        @include media-min-width($xs) {
          font-size: 16px;
        }

        @include media-min-width($m) {
          font-size: 20px;
        }
      }
    }
  }
}
