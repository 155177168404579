$fa-font-path: '/assets/fonts/FontAwesome';

@font-face {
  font-family: $font-family-FontAwesomeBrands;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-display: block;
  src: url('#{$fa-font-path}/fa-brands-400.woff2') format('woff2'),
       url('#{$fa-font-path}/fa-brands-400.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-FontAwesomeFree;
  font-style: normal;
  font-weight: $font-weight-regular;
  font-display: block;
  src: url('#{$fa-font-path}/fa-regular-400.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-regular-400.ttf') format('truetype');
}

@font-face {
  font-family: $font-family-FontAwesomeFree;
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url('#{$fa-font-path}/fa-solid-900.woff2') format('woff2'),
  url('#{$fa-font-path}/fa-solid-900.ttf') format('truetype');
}

:root, :host {
  --#{$fa-css-prefix}-font-regular: normal 400 1em/1 "#{ $fa-style-family }";
}

.far,
.fas,
.#{$fa-css-prefix}-regular,
.#{$fa-css-prefix}-solid {
  font-family: 'Font Awesome 6 Free';
}

.far,
.#{$fa-css-prefix}-regular {
  font-weight: 400;
}

.fas,
.#{$fa-css-prefix}-solid {
  font-weight: 900;
}
