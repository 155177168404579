@import "../../mixins/media";

.send-cv-section {
  background-color: $color-dark;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;

  @include media-min-width($s) {
    padding-top: 216px;
    padding-bottom: 165px;
  }

  .glitch {
    position: absolute;

    &--top-left {
      top: 0;
      left: -237px;
    }

    &--top-right {
      top: 0;
      right: 0;
    }

    &--bottom-right {
      bottom: 0;
      right: -237px;
    }
  }

  &__container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;

    &__inner {
      display: inline-block;
      position: relative;
      padding: 0 60px;

      &::before,
      &::after {
        @include media-min-width($m) {
          content: '';
          position: absolute;
          width: 100%;
          height: 140px;
          border: 1px solid $color-orange;
        }
      }

      &::before {
        top: -50px;
        left: 0;
        border-bottom: 0;
      }

      &::after {
        bottom: 32px;
        left: -36px;
        border-top: 0;
      }

      .heading {
        font-size: 40px;
        font-family: $font-family-GlitchInside;
        text-align: center;

        @include media-min-width($xs) {
          font-size: 50px;
        }

        @include media-min-width($s) {
          font-size: 64px;
        }
      }

      p {
        font-weight: $font-weight-medium;
        font-size: 14px;
        line-height: 29px;
        margin-top: 24px;
        text-align: center;

        @include media-min-width($xs) {
          font-size: 16px;
        }

        @include media-min-width($m) {
          font-size: 20px;
        }
      }
    }
  }

  button {
    margin-top: 22px;
    position: relative;
    z-index: 2;
  }
}
