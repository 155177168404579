@import "../../mixins/media";

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(#2D2D2D, .69);
  justify-content: center;
  overflow: auto;
  z-index: 1000000;
  display: flex;
  align-items: flex-start;
  opacity: 0;
  transition: opacity .3s;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: unset;

    .modal-wrapper__inner {
      transform: scale(1);
    }
  }

  &__inner {
    width: calc(100% - 30px);
    max-width: 776px;
    border: 1px solid $color-orange;
    background-color: $color-dark-blue;
    margin: 10vh 0;
    transform: scale(0);
    transition: transform .3s;

    &__top {
      padding: 24px;

      button {
        cursor: pointer;
        font-size: 24px;
        color: $color-white;
      }
    }

    &__content {
      padding: 0 24px 24px 24px;

      @include media-min-width($s) {
        padding: 0 68px 47px 68px;
      }
    }
  }
}
