@import "../../mixins/media";

.why-join-us-section {
  position: relative;

  .glitch {
    &--top {
      display: none;

      @include media-min-width($xl) {
        display: block;
        position: absolute;
        right: 0;
        top: -243px;
      }
    }

    &--bottom {
      position: relative;
      left: -180px;
      margin-top: 50px;
      margin-bottom: 50px;

      @include media-min-width($s) {
        margin-top: 124px;
        margin-bottom: 56px;
      }
    }
  }

  &__container {
    margin-top: 56px;
    position: relative;

    .benefits {
      @include media-min-width($s) {
        justify-content: center;
      }
    }
  }
}
