@import "../../mixins/media";

.home-header {
  position: relative;

  img {
    max-height: 931px;
    width: 100%;
    object-fit: cover;
  }

  .scroll-down-button {
    position: absolute;
    color: $color-white;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    bottom: 30px;
    font-size: 20px;
    font-weight: $font-weight-light;
    display: none;

    @include media-min-width($s) {
      display: flex;
    }

    @include media-min-width($m) {
      bottom: 53px;
      font-size: 22px;
    }

    i {
      font-size: 22px;
      margin-bottom: 5px;

      @include media-min-width($m) {
        font-size: 24px;
      }
    }
  }
}
