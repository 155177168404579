@import "../mixins/media";

.footer {
  background-color: $color-blue;
  padding: 54px 0;

  &__container {
    flex-direction: column;

    @include media-min-width($m) {
      flex-direction: row;
    }

    &__cc-logo {
      height: 54px;
    }

    a.contact-link {
      color: $color-gray;
      display: flex;
      align-items: center;

      &--phone {
        margin-top: 30px;

        @include media-min-width($m) {
          margin-left: 54px;
          margin-top: 0;
        }
      }

      &--mail {
        text-transform: uppercase;
        margin-top: 15px;

        @include media-min-width($m) {
          margin-left: 50px;
          margin-top: 0;
        }

        @include media-min-width($l) {
          margin-left: 54px;
        }
      }

      i {
        font-size: 24px;
        color: $color-light-green;
        margin-right: 8px;
      }
    }

    .socials {
      margin-top: 30px;
      margin-left: auto;
      margin-right: auto;

      @include media-min-width($m) {
        margin-right: unset;
        margin-top: 0;
      }
    }
  }
}
