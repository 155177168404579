$color-white: #fff;
$color-black: #000;
$color-orange: #E64210;
$color-dark-blue: #013338;
$color-blue: #4C9099;
$color-light-green: #A7B719;
$color-gray: #454545;
$color-purple: #AF28A0;
$color-light-blue: #00E2F2;
$color-light-gray: #707070;
$color-red: #FF3333;
$color-dark: #001116;
