@import "../../mixins/media";

.job-offers-section {
  margin-top: 50px;
  position: relative;
  margin-bottom: 50px;

  @include media-min-width($s) {
    margin-top: 125px;
    margin-bottom: 84px;
  }

  @include media-min-width($s) {
    margin-bottom: 97px;
  }

  .glitch {
    left: 0;

    &--top {
      position: absolute;
      top: 163px;
    }

    &--bottom {
      margin-top: 50px;

      @include media-min-width($s) {
        margin-top: 84px;
      }

      @include media-min-width($m) {
        margin-top: 103px;
      }
    }
  }

  &__container {
    margin-top: 50px;
    position: relative;

    @include media-min-width($s) {
      margin-top: 84px;
    }

    &.container {
      max-width: 1214px;
    }

    .job-img {
      display: none;

      @include media-min-width($m) {
        display: block;
        width: 100%;
        max-width: 300px;
        margin-right: 17px;
        object-fit: cover;
      }

      @include media-min-width($l) {
        max-width: 375px;
        height: 570px;
      }
    }

    &__jobs {
      flex-grow: 1;
      display: flex;
      margin-top: -12px;
      flex-direction: column;

      @include media-min-width($s) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &__job {
        padding: 12px;
        width: 100%;

        @include media-min-width($s) {
          width: 50%;
          height: 50%;
        }

        &__inner {
          width: 100%;
          height: 100%;
          background-image: url("/assets/img/others/job-bg.svg");
          background-size: cover;
          padding: 20px 10px;

          @include media-min-width($s) {
            padding: 37px 32px 30px 20px;
          }

          .job-position {
            font-size: 25px;
            font-weight: $font-weight-medium;
            margin-top: 17px;
          }

          p {
            font-weight: $font-weight-light;
            line-height: 23px;
            margin-top: 9px;
          }

          .button-row {
            margin-top: 8px;

            .button {

              &::after {
                font-size: 8px;
              }

              img {
                width: 94px;
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
}
