@import "../mixins/media";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  hyphens: none;
  -moz-hyphens: none;
  -webkit-hyphens: none;
  scroll-behavior: smooth;
  outline: none;

  &[hidden] {
    display: none !important;
  }
}

body {
  background-color: $color-dark-blue;
  color: $color-white;

  &,
  input,
  textarea,
  *:not([class^="fa"])::before,
  *:not([class^="fa"])::after {
    font-family: $font-family-CeraPro;
    font-size: 14px;

    @include media-min-width($xs) {
      font-size: 16px;
    }
  }
}

.job-tag {
  padding: 8px;
  border: 2px solid;
  display: inline-block;

  &::before {
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    display: inline-block;
  }

  &--junior {
    border-color: $color-light-green;

    &::before {
      content: 'junior';
    }
  }

  &--mid {
    border-color: $color-orange;

    &::before {
      content: 'mid';
    }
  }
}
