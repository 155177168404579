@import "../../mixins/media";

.contact-section-wrapper {
  position: relative;

  img {
    &.glitch {
      &--top {
        width: 449px;
        top: -298px;
        display: none;
        position: absolute;
        left: 0;

        @include media-min-width($s) {
          display: block;
        }
      }

      &--bottom {
        position: relative;
        width: 783px;
        bottom: -10px;
        margin-top: 50px;

        @include media-min-width($s) {
          margin-top: 267px;
        }

        @include media-min-width($l) {
          margin-top: 168px;
        }
      }
    }
  }

  &__container {
    margin-top: 50px;
    flex-direction: column;
    align-items: center;

    @include media-min-width($s) {
      margin-top: 96px;
    }

    @include media-min-width($l) {
      flex-direction: row;
      align-items: unset;
    }

    &__left {
      margin-bottom: 50px;
      width: 100%;

      @include media-min-width($l) {
        margin-right: 70px;
        margin-bottom: 0;
        flex-grow: 1;
        width: unset;
      }
    }

    &__right {
      position: relative;
      width: 100%;

      @include media-min-width($s) {
        width: 625px;
      }

      .contact-info-box {
        @include media-min-width($s) {
          background-image: url("/assets/img/others/contact-box-bg.svg");
          background-repeat: no-repeat;
          padding: 30px 100px 47px 37px;
          background-size: contain;
          position: relative;
          z-index: 1;
        }

        .logo {
          width: 140px;
          margin-bottom: 18px;
        }

        .company-name {
          font-size: 25px;
          font-weight: $font-weight-medium;
        }

        p {
          font-size: 14px;
          line-height: 29px;
          font-weight: $font-weight-light;
          margin-top: 17px;
          margin-bottom: 18px;

          @include media-min-width($xs) {
            font-size: 16px;
          }

          @include media-min-width($m) {
            font-size: 20px;
          }
        }

        .contact-link {
          font-size: 14px;
          font-weight: $font-weight-light;
          display: flex;
          align-items: center;

          @include media-min-width($xs) {
            font-size: 16px;
          }

          @include media-min-width($m) {
            font-size: 20px;
          }

          &--phone {
            margin-bottom: 10px;
          }

          &--mail {
            margin-bottom: 18px;
          }

          &--phone,
          &--mail {
            i {
              font-size: 16px;
              color: $color-light-green;
              margin-right: 20px;
            }
          }

          &--web {
            img {
              width: 16px;
              margin-right: 20px;
            }
          }
        }
      }

      .arrow {
        display: none;

        //@include media-min-width($s) {
        //  display: block;
        //  position: absolute;
        //  top: 96px;
        //  right: 34px;
        //  width: 76px;
        //  height: 29px;
        //}
      }

      .map-container {
        width: 100%;
        margin-top: 50px;
        position: relative !important;
        height: 200px;

        &>div {
         top: 25px !important;
        }

        @include media-min-width($s) {
          position: absolute !important;
          width: 452px;
          left: 174px;
          top: 174px;
          margin-top: 0;
          height: 332px;
        }

        &::after {
          @include media-min-width($s) {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            pointer-events: none;
            background-image: url("/assets/img/others/map-bg.svg");
          }
        }
      }
    }
  }
}
