@import "../../mixins/media";

.instagram-section {
  &__container {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $color-dark-blue;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-min-width($s) {
      margin-top: 92px;
      margin-bottom: 181px;
    }

    img {
      width: 100%;
      position: relative;
      z-index: 1;
      object-fit: cover;

      @include media-min-width($xs) {
        width: 50%;
      }

      @include media-min-width($s) {
        width: 33.3%;

        &:nth-child(2),
        &:nth-child(5),
        &:nth-child(8),
        &:nth-child(10) {
          top: 40px;
        }
      }

      @include media-min-width($m) {
        width: 25%;

        &:nth-child(5),
        &:nth-child(10) {
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(6),
        &:nth-child(8),
        &:nth-child(9) {
          top: 40px;
        }
      }

      @include media-min-width($l) {
        width: 20%;

        &:nth-child(6),
        &:nth-child(8) {
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(9) {
          top: 40px;
        }
      }
    }
    //flex-direction: column;
    //
    //@include media-min-width($m) {
    //  flex-direction: row;
    //}
    //
    //.col {
    //  width: 20%;
    //  display: flex;
    //  position: relative;
    //  z-index: 1;
    //
    //  @include media-min-width($m) {
    //    flex-direction: column;
    //
    //    &:nth-child(even) {
    //      margin-top: 40px;
    //    }
    //  }
    //
    //  img {
    //    width: 100%;
    //    object-fit: cover;
    //  }
    //}
  }
}
