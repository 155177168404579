.socials {
  li {
    &:not(:last-child) {
      margin-right: 30px;
    }

    i {
      font-size: 24px;
      color: $color-white;
    }
  }
}
