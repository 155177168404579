@import "../mixins/media";

.nav {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $color-dark-blue;

  @include media-min-width($m) {
    padding-top: 56px;
    padding-bottom: 33px;
  }

  @include media-min-width($l) {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  &__container {
    @include media-min-width($m) {
      flex-direction: column;
      align-items: center;
    }

    @include media-min-width($l) {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &__logo {
      margin-right: auto;

      @include media-min-width($m) {
        margin-right: unset;
      }
    }

    .open-menu-button {
      margin-left: auto;

      @include media-min-width($m) {
        display: none;
      }

      i {
        font-size: 24px;
        color: $color-white;
      }
    }


    &__right {
      position: fixed;
      top: 0;
      right: -320px;
      width: 320px;
      height: 100vh;
      z-index: 1000000;
      background-color: $color-white;
      flex-direction: column;
      padding: 30px;
      align-items: center;
      transition: transform .3s;

      &.active {
        transform: translateX(-100%);
      }

      @include media-min-width($m) {
        margin-top: 56px;
        flex-direction: row;
        position: unset;
        padding: 0;
        height: unset;
        width: unset;
        background-color: $color-dark-blue;
        align-items: flex-start;
        top: unset;
        right: unset;
      }

      @include media-min-width($l) {
        margin-top: 0;
      }

      .close-menu-button {
        display: block;
        margin-right: auto;
        margin-bottom: 57px;
        cursor: pointer;

        @include media-min-width($m) {
          display: none;
        }

        i {
          font-size: 24px;
          color: $color-dark-blue;
        }
      }

      .menu {
        flex-direction: column;

        @include media-min-width($m) {
          flex-direction: row;
        }

        li {
          &:not(:last-child) {
            margin-bottom: 48px;

            @include media-min-width($m) {
              margin-right: 48px;
              margin-bottom: 0;
            }

            @include media-min-width($l) {
              margin-right: 50px;
            }
          }

          a {
            font-size: 20px;
            position: relative;
            cursor: pointer;
            color: $color-dark-blue;

            @include media-min-width($m) {
              color: $color-white;
            }

            &:hover,
            &.active {
              &::after,
              &::before {
                opacity: 1;
              }

              &::after {
                transform: translateY(-10px);
              }

              &::before {
                transform: translateY(10px);
              }
            }

            &::after,
            &::before {
              content: '';
              position: absolute;
              width: calc(100% + 40px);
              height: 100%;
              border: 2px solid $color-orange;
              opacity: 0;
              transition: opacity .3s, transform .3s;
            }

            &::before {
              top: calc(-50% - 10px);
              left: -20px;
              border-bottom: 0;
            }

            &::after {
              bottom: calc(-50% - 10px);
              left: -28px;
              border-top: 0;
            }
          }
        }
      }

      .socials {
        margin-top: 57px;

        @include media-min-width($m) {
          margin-left: 57px;
          margin-top: 0;
        }

        @include media-min-width($l) {
          margin-left: 67px;
        }

        i {
          color: $color-light-green;
        }
      }
    }
  }
}
