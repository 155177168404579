@import "../settings/colors";
@import "../settings/typography";
@import "../settings/breakpoints";
@import "../mixins/media";

.cookies-wrapper {
  background-color: $color-light-green;
  position: fixed;
  z-index: 1000;
  left: 19px;
  right: 19px;
  padding: 19px;
  bottom: 19px;
  max-height: calc(100vh - 98px);
  overflow-y: auto;

  @include media-min-width($m) {
    width: 100%;
    max-width: 628px;
    bottom: 44px;
    padding: 36px;
    left: 44px;
  }

  &__content {
    h6 {
      margin-bottom: 16px;
    }

    &--advanced {
      &__option {
        flex-direction: column;

        @include media-min-width($s) {
          flex-direction: row;
        }

        &--technical {
          margin-bottom: 20px;
        }

        &__left {
          p {
            .option-title {
              display: block;
              margin-bottom: 13px;
            }
          }
        }

        &__right {
          margin-top: 22px;

          @include media-min-width($s) {
            margin: 22px 32px 0 36px;
          }
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: $font-weight-regular;
      color: $color-dark-blue;
      line-height: 18px;
    }

    &__buttons-row {
      margin-top: 29px;
      flex-direction: column-reverse;

      @include media-min-width($s) {
        flex-direction: row;
      }

      &__left {
        font-size: 14px;
        margin-top: 12px;

        @include media-min-width($s) {
          margin-top: 0;
        }

        .back-button {
          padding-right: 4px;
          margin-right: 4px;
          border-right: 2px solid $color-dark-blue;
          color: $color-dark-blue;
          cursor: pointer;
        }
      }

      button {
        width: 100%;

        @include media-min-width($s) {
          width: unset;
        }

        &.ghost,
        &.primary {
          padding: 8px 32px;
          font-size: 16px;
          line-height: initial;
        }

        &.ghost {
          background-color: transparent;
          font-weight: $font-weight-bold;

          & + button.primary {
            margin-bottom: 12px;
          }

          @include media-min-width($s) {
            & + button.primary {
              margin-left: 24px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
