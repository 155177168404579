@import "../../mixins/media";

.form {
  width: 100%;

  &.pointer-events-none {
    .form__fields {
      filter: blur(2px);
    }
  }

  .form-control {
    &:not(:last-child) {
      margin-bottom: 32px;
    }

    textarea {
      height: 182px;
    }

    &:focus-within  label {
      top: 0;
    }

  }

  .label-animation {
    position: relative;

    label {
      display: block;
      position: absolute;
      left: 24px;
      top: 27px;
      transform: translateY(-50%);
      pointer-events: none;
      transition: .3s;
      background-color: #013338;
      padding: 0 5px;
    }

    *:not(:placeholder-shown) ~ label {
      top: 0;
    }
  }

  .required-fields-info {
    margin: 26px 0 44px 24px;
    font-weight: $font-weight-light;
  }

  .buttons {
    justify-content: center;

    @include media-min-width($l) {
      justify-content: flex-end;
    }
  }

  .form-notification {
    margin-bottom: 30px;
    padding: 15px;
    font-weight: $font-weight-medium;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;

    &,
    &::before {
      display: inline-block;
      font-size: 18px;
    }

    &::before {
      font-weight: $font-weight-black;
      margin-right: 15px;
      font-family: $font-family-FontAwesomeFree;
    }

    &--success {
      background-color: $color-light-green;

      &::before {
        content: '\f00c';
      }
    }

    &--error {
      background-color: $color-red;

      &::before {
        content: '\f00d';
      }
    }
  }
}
