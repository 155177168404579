.form {

  &--job-apply-form {

    .cv-file-label {
      position: relative;
      border: 1px solid #A7B719;
      background-color: rgba(167,183,25,.56);
      text-align: center;
      display: block;
      padding: 17px 0;
      margin-bottom: 32px;
      cursor: pointer;
      font-weight: 900;

      span {
        font-weight: 100;
      }

      i {
        margin-right: 15px;
      }

      #cv-file-input {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        z-index: -5;
      }
    }

    .show-file-info-wrapper {
      margin: -22px 8px 32px 8px;

      img {
        margin-right: 10px;
      }

      p {
        font-weight: 100;
      }

      i {
        color: #7A8485;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
