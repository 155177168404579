@import "../../mixins/media";

.technology-section {
  margin-bottom: 50px;

  @include media-min-width($s) {
    margin-bottom: 172px;
  }

  .glitch {
    &--top {
      margin-bottom: 50px;

      @include media-min-width($s) {
        margin-bottom: 84px;
      }

      @include media-min-width($m) {
        margin-bottom: 103px;
      }
    }
  }

  .lines-img {
    position: relative;
    left: -85px;
  }

  &__container {
    margin: 50px -10px 50px -10px;

    @include media-min-width($s) {
      margin: 84px auto 65px auto;
    }

    &.container {
      max-width: 1305px;
    }

    &__box {
      width: 235px;
      height: 219px;
      margin: 13px 10px;
      background-image: url("/assets/img/others/technology-box-bg.svg");

      span {
        position: relative;
        color: $color-purple;

        &,
        &::before,
        &::after {
          font-size: 32px;
          line-height: 43px;
          font-family: $font-family-BebasNeue;
          word-wrap: break-word;
          white-space: pre;
          text-align: center;
        }

        &::before,
        &::after {
          content: attr(data-name);
          position: absolute;
          top: 0;
          left: 0;
        }

        &::before {
          z-index: 1;
          color: $color-light-blue;
          left: 3px;
        }

        &::after {
          z-index: 2;
          color: $color-white;
          left: 2px;
        }
      }
    }
  }
}
