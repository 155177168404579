@import "../mixins/media";

.job-offer-page {
  margin-top: 50px;
  margin-bottom: 50px;

  @include media-min-width($s) {
    margin-top: 96px;
    margin-bottom: 242px;
  }

  &__inner {
    position: relative;

    .glitch {
      position: absolute;

      &--left {
        left: 0;
        top: 0;
      }

      &--right {
        right: 0;

        &--1 {
          top: 0;
        }

        &--2 {
          bottom: 0;
        }
      }
    }

    &__container {
      margin-top: 50px;
      position: relative;
      z-index: 1;

      @include media-min-width($s) {
        margin-top: 147px;
      }

      &__top {
        &__inner {
          flex-direction: column;

          @include media-min-width($l) {
            flex-direction: row;
          }

          .left {
            margin-bottom: 80px;

            @include media-min-width($l) {
              margin-right: 80px;
              margin-bottom: 0;
            }

            .job-position {
              font-size: 40px;
              font-weight: $font-weight-medium;
              margin-top: 16px;
              margin-bottom: 32px;
            }

            p {
              font-size: 14px;
              line-height: 29px;
              font-weight: $font-weight-light;

              &:not(:last-child) {
                margin-bottom: 16px;
              }

              @include media-min-width($xs) {
                font-size: 16px;
              }

              @include media-min-width($m) {
                font-size: 20px;
              }

              span {
                color: $color-light-green;
              }
            }
          }

          .right {
            border: 1px solid $color-light-green;
            padding: 20px;
            width: 100%;
            max-width: 524px;
            margin-left: auto;
            margin-right: auto;
            background-color: $color-dark-blue;

            @include media-min-width($xs) {
              padding: 40px;
            }

            @include media-min-width($l) {
              margin-top: 56px;
              margin-left: unset;
              margin-right: unset;
              min-width: 524px;
            }

            h4 {
              color: $color-light-green;
              font-size: 28px;
              font-family: $font-family-DIN2014;
            }

            .location,
            .contract-types {
              font-family: $font-family-DINPRO;

              i {
                margin-right: 8px;
              }
            }

            .location {
              margin-top: 33px;

              a {
                color: $color-orange;
                display: block;
                margin-left: 20px;
                font-weight: $font-weight-medium;
              }
            }

            .contract-types {
              margin-top: 30px;
            }

            .tags {
              margin-top: 24px;

              .tags-list {
                width: calc(100% + 12px);
                margin-top: -6px;
                margin-right: -6px;
                margin-left: -6px;
                justify-content: flex-start;

                @include media-min-width($m) {
                  max-width: 480px;
                }

                .tag {
                  border: 1px solid $color-orange;
                  font-size: 11px;
                  line-height: 14px;
                  font-family: $font-family-DINPRO;
                  font-weight: $font-weight-regular;
                  padding: 4px 26px;
                  margin: 6px 8px;
                }
              }
            }

            .button {
              margin-top: 34px;
            }
          }
        }
      }

      &__bottom {
        margin-top: 50px;

        @include media-min-width($s) {
          margin-top: 105px;
        }

        .list {
          display: flex;
          flex-direction: column;
          align-items: center;

          @include media-min-width($s) {
            align-items: flex-start;
          }

          &:not(.list--last) {
            margin-bottom: 50px;

            @include media-min-width($s) {
              margin-bottom: 85px;
            }
          }

          h6 {
            font-size: 30px;
            margin-bottom: 27px;
            text-align: center;

            @include media-min-width($xs) {
              font-size: 40px;
            }

            @include media-min-width($s) {
              text-align: left;
            }
          }
        }

        .button-row {
          margin-top: 50px;

          @include media-min-width($s) {
            margin-top: 100px;
          }
        }
      }
    }
  }
}
