@import "../mixins/media";

ol,
ul {
  list-style-type: none;
}

ul {
  &.custom-list {
    li {
      font-size: 14px;
      font-weight: $font-weight-light;
      line-height: 36px;
      display: flex;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 5px;
        margin-right: 16px;
        margin-top: 14px;

        @include media-min-width($xs) {
          width: 43px;
        }

        @include media-min-width($m) {
          width: 62px;
          height: 7px;
        }
      }

      &:nth-child(odd) {
        &::before {
          background-image: url("/assets/img/others/lines-2.svg");
        }
      }

      &:nth-child(even) {
        &::before {
          background-image: url("/assets/img/others/lines-3.svg");
        }
      }

      @include media-min-width($xs) {
        font-size: 16px;
      }

      @include media-min-width($m) {
        font-size: 20px;
      }

      @include media-min-width($l) {
        font-size: 25px;
      }
    }
  }
}
