@import "../../mixins/media";

.header-section {
  padding: 0 15px;
  justify-content: center;

  @include media-min-width($s) {
    padding: 0;
    justify-content: unset;
  }

  h3 {
    text-transform: uppercase;
    font-size: 35px;
    font-family: $font-family-GlitchInside;
    font-weight: $font-weight-regular;
    -webkit-text-stroke-color: $color-light-green;
    -webkit-text-stroke-width: 1px;
    word-break: break-word;
    width: 100%;
    text-align: center;

    &:not(:last-child) {
      @include media-min-width($s) {
        margin-right: 70px;
      }
    }

    &:not(.active) {
      -webkit-text-fill-color: transparent;
      display: none;

      @include media-min-width($s) {
        display: block;
      }
    }

    @include media-min-width($xs) {
      font-size: 40px;
    }

    @include media-min-width($s) {
      white-space: nowrap;
      word-break: unset;
      width: unset;
      text-align: unset;
    }

    @include media-min-width($m) {
      font-size: 60px;
    }

    @include media-min-width($l) {
      font-size: 64px;
    }

    &.active {
      color: $color-white;
    }
  }
}
