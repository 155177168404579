@import "../settings/colors";

.toggle-switch {
  width: 66px;
  height: 19px;
  border: 1px solid $color-dark-blue;
  display: block;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  padding: 3px 4px;
  cursor: pointer;

  input[type="checkbox"] {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }

  input[type="checkbox"]:checked + span {
    left: calc(100% - 17px);
    background-color: #E64210;
  }

  span {
    width: 13px;
    height: 13px;
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    background-color: $color-dark-blue;
    z-index: 1;
    border-radius: 50%;
    transition: all .3s;
    pointer-events: none;
  }
}
