@import "../mixins/media";

.prevent-click {
  pointer-events: none;
}

.send-cv-section-wrapper {
  padding-bottom: 183px;
  position: relative;

  .form {
    position: relative;
    max-width: 1184px;
    margin: 50px auto;
    padding: 0 20px;

    select {
      background: none;
      border-color: #A7B719;
      font-family: $font-family-CeraPro;
      color: #ffffff;
      font-size: 16px;
      width: 100%;
      padding: 16px 24px 20px 24px;

      @include media-max-width($xs) {
        font-size: 15px;
      }

      option {
        background-color: #013338;
        color: #A7B719;
      }
    }

    fieldset {
      padding: 10px;
      border-color: #A7B719;

      legend {
        padding: 0 10px;
      }

      .options-wrapper {
        margin-bottom: 20px;
        flex-wrap: wrap;
      }

      .checkbox-wrapper {
        padding: 10px 15px;
        min-width: 140px;

        input[type='checkbox'] {
          width: 15px;
          accent-color: #A7B719;
        }
      }

      label {
        cursor: pointer;
      }
    }

    .cv-form-btn {
      margin-top: 50px;
    }

    .hidden-input {
      transition-duration: 1s;
    }
  }

  img {
    &.glitch {
      position: absolute;
      left: 0;

      &--top {
        width: 449px;
        top: -298px;
      }

      &--bottom {
        width: 783px;
        bottom: -5px;
      }
    }
  }
}

.after-send-notification {
  flex-direction: column;
  margin: 150px 0;
  padding: 0 20px;

  h1 {
    text-align: center;
  }

  a {
    margin-top: 50px;
    position: relative;

    &:after {
      text-align: center;
      content: 'Back to form';
      position: absolute;
      font-family: "Cera Pro";
      font-weight: 900;
      text-transform: uppercase;
      transform: translate(-50%, -50%);
      top: 45%;
      left: 50%;
    }
  }
}

.loader {
  border: 13px solid rgba(255, 255, 255, .1);
  border-radius: 50%;
  border-top: 13px solid #A7B719;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hidden {
  display: none;
}


