button {
  border: 0;
  background-color: inherit;
}

.button {
  text-transform: uppercase;
  position: relative;
  cursor: pointer;

  &::after {
    content: attr(data-text);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: $font-weight-black;
    color: $color-white;
  }
}
