$font-family-GlitchInside: 'GlitchInside';
$font-family-CeraPro: 'CeraPro';
$font-family-BebasNeue: 'BebasNeue';
$font-family-DIN2014: 'DIN 2014';
$font-family-DINPRO: 'DIN PRO';
$font-family-FontAwesomeFree: 'Font Awesome 6 Free';
$font-family-FontAwesomeBrands: 'Font Awesome 6 Brands';

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-demi: 600;
$font-weight-bold: 700;
$font-weight-black: 900;


@mixin font-face($font-family, $font-weight, $path, $isTTF) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-display: swap;
  src: url("/assets/fonts#{$path}.woff") format("woff"),
       url("/assets/fonts#{$path}.#{if($isTTF, "ttf", "otf")}") format("#{if($isTTF, "truetype", "opentype")}");
}

@font-face {
  @include font-face($font-family-DINPRO, $font-weight-regular, "/DINPRO/DINPRO", true);
}

@font-face {
  @include font-face($font-family-DINPRO, $font-weight-medium, "/DINPRO/DINPro-Medium", true);
}

@font-face {
  @include font-face($font-family-DIN2014, $font-weight-light, "/DIN2014/DIN2014-Light", true);
}

@font-face {
  @include font-face($font-family-DIN2014, $font-weight-demi, "/DIN2014/DIN2014-Demi", true);
}

@font-face {
  @include font-face($font-family-BebasNeue, $font-weight-regular, "/BebasNeue/BebasNeue-Regular", false);
}

@font-face {
  @include font-face($font-family-GlitchInside, $font-weight-regular, "/GlitchInside/GlitchInside", false);
}

@font-face {
  @include font-face($font-family-CeraPro, $font-weight-light, "/CeraPro/Cera-Pro-Light", false);
}

@font-face {
  @include font-face($font-family-CeraPro, $font-weight-regular, "/CeraPro/Cera-Pro-Regular", false);
}

@font-face {
  @include font-face($font-family-CeraPro, $font-weight-medium, "/CeraPro/Cera-Pro-Medium", false);
}

@font-face {
  @include font-face($font-family-CeraPro, $font-weight-black, "/CeraPro/Cera-Pro-Black", false);
}

